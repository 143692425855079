.projects-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.561);
    padding: 40px;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 20%;
    box-shadow: 0 0 4px #ffffff84, 0 0 11px #ffffff75, 0 0 10px #ffffff79, 0 0 30px rgba(0, 255, 170, 0.661),
        0 0 70px rgba(0, 255, 170, 0.627), 0 0 80px rgba(0, 255, 170, 0.539), 0 0 90px rgba(0, 255, 170, 0.371), 0 0 120px rgba(0, 255, 170, 0.411);
}


.project-item {
    margin-bottom: 50px;
}

h3 {
    margin: 10px 10px;
    text-align: center;
    text-shadow: 0 0 4px #ffffff84, 0 0 11px #ffffff75, 0 0 10px #ffffff79, 0 0 30px rgba(0, 255, 170, 0.661),
        0 0 70px rgba(0, 255, 170, 0.627), 0 0 80px rgba(0, 255, 170, 0.539), 0 0 90px rgba(0, 255, 170, 0.371), 0 0 120px rgba(0, 255, 170, 0.411);

}

.project-images {
    padding: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.see-more {
    text-align: center;
    padding: 10px;
    width: fit-content;
    border-bottom: 1px solid white;
    text-shadow: 0 0 4px #ffffff84, 0 0 11px #ffffff75, 0 0 10px #ffffff79, 0 0 30px rgba(0, 255, 170, 0.661),
        0 0 70px rgba(0, 255, 170, 0.627), 0 0 80px rgba(0, 255, 170, 0.539), 0 0 90px rgba(0, 255, 170, 0.371), 0 0 120px rgba(0, 255, 170, 0.411);

}

.see-more a {
    text-decoration: none;
    color: #fff;
    font-size: 30px;
}


.project-images .mobile {
    width: 150px;
    border-radius: 25px;
    padding: 5px;
}

.project-images .desktop {
    width: 100%;
}

@media (max-width: 450px) {
    .projects-container {
        padding: 10px;
    }

    .project-images .mobile {
        width: 50%;
        border-radius: 20px;
        padding: 5px;
    }

    .see-more a {
        font-size: 15px;
    }
}



@media (min-width: 1000px) {
    .project-images .mobile {
        width: 20%;
        border-radius: 30px;
        padding: 5px;
    }
}