.gallery {
    width: 80vw;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: absolute;
    top: 70%;
    margin: 0 10% 0 10%;
}

.filters {
    position: absolute;
    right: 10%;
    top: 20%;
    display: flex;
    flex-direction: column;
}

.filter-button {
    background-color: rgba(0, 0, 0, 0.561);
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    border: none;
    font-family: inherit;
}

.filter-button:active {
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px rgb(213, 98, 87),
        0 0 82px rgb(213, 98, 87), 0 0 92px rgb(213, 98, 87), 0 0 102px rgb(213, 98, 87), 0 0 151px rgb(213, 98, 87);

}

.filter-button:hover {
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px rgb(213, 98, 87),
        0 0 82px rgb(213, 98, 87), 0 0 92px rgb(213, 98, 87), 0 0 102px rgb(213, 98, 87), 0 0 151px rgb(213, 98, 87);

}


.gallery .project-item {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.561);
    padding: 40px;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 10%;
    box-shadow: 0 0 4px #ffffff84, 0 0 11px #ffffff75, 0 0 10px #ffffff79, 0 0 30px rgba(0, 255, 170, 0.661),
        0 0 70px rgba(0, 255, 170, 0.627), 0 0 80px rgba(0, 255, 170, 0.539), 0 0 90px rgba(0, 255, 170, 0.371), 0 0 120px rgba(0, 255, 170, 0.411);

}

.project-item img {
    width: 150px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    border-radius: 10px;
}


.project-item img:hover {
    filter: none;
    -webkit-filter: none;
}

.tech {
    display: flex;
    flex-wrap: wrap;
}


@media (max-width: 500px) {
    .gallery {
        width: 70vw;
        right: 0;
        margin: 0 5% 0 5%;
    }

    .gallery .project-item {
        flex-wrap: wrap;
    }

    .link_wrapper {
        width: 50%;
    }

    .gallery img {
        width: 100%;
    }
}

@media (min-width: 501px) {
    .gallery {
        width: 70vw;
        right: 0;
        margin: 0 5% 0 5%;
    }

    .gallery .project-item {
        flex-wrap: wrap;

    }

    .link_wrapper {
        width: 50%;
    }

    .gallery img {
        width: 100%;
    }
}

@media (min-width: 800px) {

    .gallery .project-item {
        flex-direction: row;
        justify-content: flex-end;
    }

    .link_wrapper {
        width: 20%;
    }

    .gallery img {
        width: 100%;
    }

}

@media (min-width: 1000px) {

    .gallery .project-item {
        flex-direction: row;
    }

    .link_wrapper {
        width: 20%;
    }

    .gallery img {
        width: 100%;
    }

}