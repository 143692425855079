.ux-container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.561);
    padding: 40px;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 7px #ffffffa4, 0 0 10px #ffffff95, 0 0 21px #ffffffc3, 0 0 42px rgba(214, 156, 89, 0.758),
        0 0 82px rgba(214, 156, 89, 0.711), 0 0 92px rgba(214, 156, 89, 0.521), 0 0 102px rgba(214, 156, 89, 0.509), 0 0 151px rgba(214, 156, 89, 0.565);

}

.ux-element {
    height: 80%;
    position: absolute;
    left: 10%;
    top: 0;
    overflow: hidden;

}

h3 {
    margin: 10px 10px;
    text-shadow: 0 0 7px #ffffffa4, 0 0 10px #ffffff95, 0 0 21px #ffffffc3, 0 0 42px rgba(214, 156, 89, 0.758),
        0 0 82px rgba(214, 156, 89, 0.711), 0 0 92px rgba(214, 156, 89, 0.521), 0 0 102px rgba(214, 156, 89, 0.509), 0 0 151px rgba(214, 156, 89, 0.565);

}

.ux-images {
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.ux-images img {
    width: 80%;
    padding: 5px;

}

@media (max-width: 450px) {
    .ux-container {
        padding: 10px;
    }

    .ux-images .desktop {
        width: 100%;
        padding: 5px;
    }
}