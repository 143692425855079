.links {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    margin: auto;
    list-style-type: none;
    z-index: 6;
    display: flex;
    flex-direction: column;
}


.link {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-size: 25px;
    color: white;
    z-index: 5;
}

.icon {
    box-shadow: 0 0 7px rgb(216, 128, 120), 0 0 10px rgb(216, 128, 120), 0 0 21px rgb(216, 128, 120), 0 0 42px rgb(213, 98, 87),
        0 0 82px rgb(213, 98, 87), 0 0 92px rgb(213, 98, 87), 0 0 102px rgb(213, 98, 87), 0 0 151px rgb(213, 98, 87);
    background-color: rgb(188, 110, 103);
    border-radius: 50%;
    padding: 4px;
}


.icon:hover {
    color: #22222281;
}

@media (max-width: 390px) {
    .link {
        font-size: 18px;
    }
}