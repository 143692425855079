.home-page-projects {
    width: 74vw;
    position: absolute;
    top: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 13% 0 13%;
}

@media (max-width: 500px) {
    .home-page-projects {
        right: 0;
        margin: 0 5% 0 5%;
    }
}