:root {
  --bg: #282c34;
  --design: rgb(233, 170, 39);
  --language: rgb(246, 149, 149);
  --library-framework: rgb(62, 179, 210);
  --database: rgb(127, 194, 40);
  --other: rgb(131, 122, 210);
  --data-analytics: rgb(241, 214, 36);
  --ux: rgb(232, 74, 93);
}

* {
  box-sizing: border-box;
}

.App {
  font-family: JetBrainsMono;
}


@font-face {
  font-family: 'JetBrainsMono';
  src: local('JetBrainsMono'), url(./fonts/JetBrainsMono/JetBrainsMono-Medium.ttf) format('truetype')
}

@font-face {
  font-family: 'JetBrainsMono';
  font-weight: 800;
  src: local('JetBrainsMono'), url(./fonts/JetBrainsMono/JetBrainsMono-Bold.ttf) format('truetype')
}

@font-face {
  font-family: 'JetBrainsMono';
  font-weight: 300;
  src: local('JetBrainsMono'), url(./fonts/JetBrainsMono/JetBrainsMono-Light.ttf) format('truetype')
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
}

.home_image {
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: -1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.page_3_img {
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: -1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}



.splash_text {
  font-family: 'JetBrainsMono';
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.561);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  position: absolute;
  top: 10%;
  margin-left: 20px;
}


.container_splash_text_2,
.container_splash_text_3,
.container_splash_text_4 {
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.splash_text_2 {
  font-family: 'JetBrainsMono';
  font-weight: 300;
  position: absolute;
  /* text-decoration: underline; */
  top: 45%;
  padding: 10px;
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 7px rgb(216, 128, 120), 0 0 10px rgb(216, 128, 120), 0 0 21px rgb(216, 128, 120), 0 0 42px rgb(213, 98, 87),
    0 0 82px rgb(213, 98, 87), 0 0 92px rgb(213, 98, 87), 0 0 102px rgb(213, 98, 87), 0 0 151px rgb(213, 98, 87);

}


.splash_text_3 {
  font-family: 'JetBrainsMono';
  font-weight: 300;
  position: absolute;
  top: 50%;
  padding: 10px;
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 7px rgb(113, 194, 219), 0 0 10px rgb(113, 194, 219), 0 0 21px rgb(113, 194, 219), 0 0 42px rgb(99, 170, 192),
    0 0 82px rgb(99, 170, 192), 0 0 92px rgb(99, 170, 192), 0 0 102px rgb(99, 170, 192), 0 0 151px rgb(99, 170, 192);

}

.splash_text_4 {
  font-family: 'JetBrainsMono';
  font-weight: 300;
  padding: 10px;
  position: absolute;

  top: 55%;
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px rgb(214, 156, 89),
    0 0 82px rgb(214, 156, 89), 0 0 92px rgb(214, 156, 89), 0 0 102px rgb(214, 156, 89), 0 0 151px rgb(214, 156, 89);
}

.asticodes {
  font-size: 55px;
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  animation: flicker 1.5s infinite alternate;
}

.line {
  position: absolute;
  top: 35%;
  left: 15%;
  width: 5px;
  height: 380%;
  background: #22222281;
  z-index: 99999;
  overflow: hidden;
  border-radius: 5px;
}

.top-line {
  position: absolute;
  width: 3px;
  height: 30%;
  background: #fff;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  z-index: 99999;
  overflow: hidden;
  top: 40px;
  right: 15%;
}

.top-line-2 {
  position: absolute;
  width: 3px;
  height: 20%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px rgb(213, 98, 87),
    0 0 82px rgb(213, 98, 87), 0 0 92px rgb(213, 98, 87), 0 0 102px rgb(213, 98, 87), 0 0 151px rgb(213, 98, 87);
  z-index: 99999;
  overflow: hidden;
  top: 50px;
  right: 20%;
}

.dot {
  position: absolute;
  height: 15px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  animation: dotdown 8s cubic-bezier(.72, .03, .75, .54) infinite;
}

.dot1 {}

.dot2 {
  animation-delay: 3.4s;
}

.dot3 {
  animation-delay: 3.8s;
}

.dot4 {
  animation-delay: 4.2s;
}

.dot5 {
  animation-delay: 4.6s;
}

@keyframes dotdown {
  0% {
    top: 0%;
  }

  100% {
    top: 100%;
  }
}

/* Flickering animation */
@keyframes flicker {

  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

@keyframes flicker2 {

  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }

  20%,
  24%,
  55% {
    box-shadow: none;
  }
}

@keyframes flicker3 {

  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

.page_2_img {
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: -1;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}


.card {
  width: 80%;
  background-color: rgba(0, 0, 0, 0.561);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}

.card-1 {
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  position: absolute;
  left: 10%;
  top: 0;
}


.line-2 {
  position: absolute;
  top: 62%;
  right: 15%;
  width: 5px;
  height: 350%;
  background: #22222281;
  z-index: 99999;
  overflow: hidden;
  border-radius: 5px;
}

.dot2 {
  position: absolute;
  height: 5px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  animation: dotdown2 10s cubic-bezier(.82, .05, .71, .50) infinite;
}

.dot21 {}

.dot22 {
  animation-delay: 1.4s;
}

.dot23 {
  animation-delay: 1.8s;
}

.dot24 {
  animation-delay: 2s;
}

.dot25 {
  animation-delay: 2.2s;
}

.dot26 {
  animation-delay: 3s;
}

.dot27 {
  animation-delay: 3.2s;
}

.dot28 {
  animation-delay: 3.1s;
}

@keyframes dotdown2 {
  0% {
    top: 0%;
  }

  100% {
    top: 100%;
  }
}

.card h3 {
  text-align: center;
}

@media (max-width: 472px) {
  .line {
    left: 25%;
  }
}

@media (min-width: 500px) {
  .links {
    left: 0;
  }

  .splash_text {
    font-size: 50px;
  }

  .card-1 {
    height: 80%;
  }

  .line {
    top: 50%;
  }

}

@media (min-width: 600px) {
  .med-hide-2 {
    display: none;
  }
}

@media (min-width: 1000px) {

  .splash_text {
    font-size: 50px;
    padding: 50px;
  }

  .asticodes {
    font-size: 100px;
  }

  .splash_text_4 {
    font-size: 30px;
    top: 58%;
  }


  .splash_text_3 {
    font-size: 30px;
    top: 48%;
  }

  .splash_text_2 {
    font-size: 30px;
    top: 38%;
  }

  .top-line {
    width: 10px;
    border-radius: 10px;
  }

  .top-line-2 {
    width: 10px;
    border-radius: 10px;
  }

  .line {
    top: 60%;
    width: 8px;
    height: 300%;
    border-radius: 10px;
  }

  .line-2 {
    width: 8px;
    height: 280%;
    border-radius: 10px;
    top: 70%;
  }

  .links {
    position: fixed;
    left: 0;

  }

  .desktop-hide {
    display: none;
  }



}

.skills {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.skill {
  height: 22px;
  border-radius: 8px;
  padding: 0 8px;
  margin: 5px 8px 5px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
}

.skill-project-page {
  margin: 5px 8px 5px 0;
  text-transform: lowercase;
  padding: 0 8px;
  border-radius: 8px;

}

.design {
  background-color: var(--design);
  color: black;
}

.ux {
  background-color: var(--ux);
  color: black;
}

.other {
  background-color: var(--other);
  color: black;

}

.language {
  background-color: var(--language);
  color: black;

}

.library-framework {
  background-color: var(--library-framework);
  color: black;

}

.database {
  background-color: var(--database);
  color: black;

}